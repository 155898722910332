import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import Carousel from "../components/Carousel"

const AlexaMini = ({ data, location }) => {

  const url = `https://speedcambrasil.com.br${location.pathname}`

  let carouselImages = []
  data.allCarouselAlexa.edges.forEach(element => {
    carouselImages.push({ img: getImage(element["node"]) })
  })

  return (
    <>
      <Metatags
        title="Speedcam Brasil - Arri Alexa Mini"
        description="Speed Cam - Rental House"
        url={url}
        image="https://speedcambrasil.com.br/logo.png"
        keywords="speed cam, rental, Phanton, Alexa Mini, Alexa 35, Innovision HD, Innovision HR, Cinemagic Revolution, Laowa 12, Laowa 24"
        language="pt-br"
      />
      <NavBar />
      <div className="container mx-auto text-center">
        <h1 className="text-4xl m-4 md:text-6xl font-semibold text-sc-yellow">
          <i>Arri Alexa Mini</i>
        </h1>
        <div className="h-20" />
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <Carousel picts={carouselImages} />
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="container mx-auto text-xl m-4">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-2" />
          <div className="col-span-8">
            <p>
              Compacta, leve e autônoma - a <b>ARRI ALEXA Mini</b> é a pequena e
              versátil multifuncional da família de câmeras ARRI.
            </p>
            <p>
              O design simétrico permite filmar em qualquer orientação,
              incluindo de cabeça para baixo e no modo retrato, enquanto vários
              pontos de conexão de acessórios permitem soluções de montagem
              altamente criativas. Além disso, a ALEXA Mini vem com uma série de
              montagens de lentes intercambiáveis, permitindo o uso de vídeo B4,
              lentes estáticas de montagem EF ou até mesmo ARRI Signature Primes
              com a montagem LPL para ALEXA Mini.
            </p>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="container mx-auto text-xl">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-3" />
          <div className="col-span-8">
            <div>
              <h1 className="my-2">Principais características:</h1>

              <ul className="list-disc list-inside">
                <li>
                  Estável em ambientes agressivos de -20° a +45°C / -4° a +113°F
                </li>
                <li>Eletrônica blindada</li>
                <li>Sem condensação e à prova de poeira</li>
                <li>Filtros FSND motorizados embutidos 0.6, 1.2, 2.1</li>

                <li>Câmera digital estilo filme de formato de 35 mm</li>
                <li>Tons de pele naturais</li>
                <li>Gravação de até 200fps</li>
                <li>
                  Maior range dinâmico com mais de 14 stops de latitude de
                  exposição
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="h-12" />
      <div className="container mx-auto text-center">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-2" />
          <div className="col-span-8">
            <div className="m-auto">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                <div className="btn-wrapper profile text-center col-span-4">
                  <a
                    href="./pdfs/ALEXA_Mini_Leaflet.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="rounded text-opacity-90 text-sc-gray bg-yellow-400 hover:bg-yellow-500 active:bg-yellow-500 focus:outline-none focus:ring focus:bg-yellow-300 px-2 py-2 mx-auto"
                      style={{ width: "200px" }}
                    >
                      folder
                    </div>
                  </a>
                </div>
                <div className="btn-wrapper profile text-center col-span-4">
                  <a
                    href="./pdfs/ALEXA_Mini_-_Quick_Guide.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="rounded text-opacity-90 text-sc-gray bg-yellow-400 hover:bg-yellow-500 active:bg-yellow-500 focus:outline-none focus:ring focus:bg-yellow-300 px-2 py-2 mx-auto"
                      style={{ width: "200px" }}
                    >
                      guia rápido
                    </div>
                  </a>
                </div>
                <div className="btn-wrapper profile text-center col-span-4">
                  <a
                    href="./pdfs/2016_05-22_ALEXA_Mini_Dimensions.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="rounded text-opacity-90 text-sc-gray bg-yellow-400 hover:bg-yellow-500 active:bg-yellow-500 focus:outline-none focus:ring focus:bg-yellow-300 px-2 py-2 mx-auto"
                      style={{ width: "200px" }}
                    >
                      mechanical drawing
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer />
    </>
  )
}

export default AlexaMini

export const query = graphql`
  {
    allCarouselAlexa: allFile(
      filter: { relativePath: { regex: "/alexa-page/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1536)
          }
        }
      }
    }
  }
`
